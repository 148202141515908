import React from 'react';
import Box from '@mui/material/Box'
import Nav from "./Nav"
import Container from "@mui/material/Container";
import Homepage from '../components/Homepage'

function App() {
  return (
    <Box>
      <Nav />
      <Box component="main" sx={{ flexGrow: 1, minHeight: '100vh' }}>
        <Container sx={{ mt: 4, mb: 4 }}>
            <Homepage />
        </Container>
      </Box>
    </Box>
  );
}

export default App;

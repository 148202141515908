import React from "react"
import logo from '../assets/logo.svg';
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'

const Nav = () => {

    return (
        <AppBar position="static" elevation={1} sx={{mb: 0.5}}>
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    <Typography
                        variant="h6"
                        noWrap
                        component="a"
                        href="/"
                        sx={{
                            mr: 2,
                            display: {xs: 'none', md: 'flex'},
                            fontFamily: 'monospace',
                            fontWeight: 700,
                            letterSpacing: '.3rem',
                            color: 'inherit',
                            textDecoration: 'none',
                        }}
                    >
                        <img
                            height="100"
                            width="100"
                            src={"/xOoIx.png"}
                            alt="F.U.C.K"
                            title="F.U.C.K"
                        />
                    </Typography>
                    <Box sx={{flexGrow: 1, display: {xs: 'flex', md: 'none'}}}>
                    </Box>
                    <Typography
                        variant="h5"
                        noWrap
                        component="a"
                        href="/"
                        sx={{
                            mr: 2,
                            display: {xs: 'flex', md: 'none'},
                            flexGrow: 1,
                            fontFamily: 'monospace',
                            fontWeight: 700,
                            letterSpacing: '.3rem',
                            color: 'inherit',
                            textDecoration: 'none',
                        }}
                    >
                        <img
                            height="100"
                            width="100"
                            src={"/xOoIx.png"}
                            alt="F.U.C.K"
                            title="F.U.C.K"
                        />
                    </Typography>
                    <Box sx={{flexGrow: 1, display: {xs: 'none', md: 'flex'}, justifyContent: 'center'}}>
                    </Box>
                    <Box sx={{flexGrow: 0}}>
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    )
}

export default Nav